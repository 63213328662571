import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useCluster from "./hooks/useCluster";

import Loading from "../../components/Loading/Loading";

import ChartSlider from "../../components/ChartSlider/ChartSlider";
import SearchVolMonth from "./Visualizations/SearchVolMonth";
import SearchVolBubble from "./Visualizations/SearchVolBubble";
import PaidSERP from "./Visualizations/PaidSERP";
import ClusterSERPFeatures from "./Visualizations/ClusterSERPFeatures";
import ClusterTable from "./Visualizations/ClusterTable";
import Lightbox from "./Lightbox/Lightbox";

import { formatCurrency, formatFloat, formatPercent } from "../../utils/tableUtils";

import "./Cluster.css";

import dragon from "../../assets/img/dragon.webp";

function Cluster() {
  const location = useLocation();
  const history = location.state?.history || [];
  const { clusterId, profileId } = useParams();
  const { cluster, loading, error } = useCluster(clusterId, profileId);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="page-base space-y-4 md:space-y-8">
        <section className="page-section p-4 grid place-items-center text-center min-h-[450px]">
          <div className="space-y-4">
            <h1 className="text-2xl">Error fetching data</h1>
            <p className="text-red-500 font-mono">
              { error?.message || "Something went wrong." }
            </p>
          </div>
        </section>
      </div>
    );
  }

  if(cluster == null || Object.keys(cluster).length === 0) {
    return (
      <div className="page-base space-y-4 md:space-y-8">
        <section className="page-section p-4 grid place-items-center text-center min-h-[450px]">
          <div className="space-y-4">
            <h1 className="text-2xl">No data available</h1>
            <p className="font-mono">No cluster data available for this profile.</p>
          </div>
        </section>
      </div>
    );
  }

  return <>
    <div className="cluster page-base space-y-4 md:space-y-8">
      <section className="page-section">
        <h1 className="section-header">{cluster.cluster_data.title}</h1>
        
        <div className="cluster-overview">
          <div className="row-span-2 border-[#0561B7] border border-opacity-50 rounded">
            <Lightbox
              imageSrc={cluster.cluster_data.cluster_img || dragon}
              title={cluster.cluster_data.title}
              summary={cluster.cluster_data.summary}
            />
          </div>
          <div className="bg-[#0561B7] bg-opacity-10 rounded border-[#0561B7] border border-opacity-50 p-4 text-xl">
            <p>{cluster.cluster_data.summary}</p>
          </div>

          <div className="row-1">
            <div className="page-section w-full p-4 space-y-3 bg-[#0561B7] bg-opacity-10">
              { cluster?.cluster_data?.primary_category && <div className="flex gap-4 items-center">
                  <h2 className="font-bold min-w-[187px]">
                    Category:
                  </h2>
                  <p className="flex-1">
                    { cluster.cluster_data.primary_category }
                  </p>
                </div>}
                <div className="flex gap-4 items-center">
                <h2 className="font-bold min-w-[187px]">Share of Branded:</h2>
                <p className="flex-1">
                  { formatPercent(cluster?.cluster_data?.visualizations?.cluster_share[1].y, 2) }
                </p>
              </div>
              <div className="flex gap-4 items-center">
                <h2 className="font-bold min-w-[187px]">Total KW Count:</h2>
                <p className="flex-1">
                { formatFloat(cluster?.cluster_data?.total_keyword_count, 0) }
                </p>
              </div>
              <div className="flex gap-4 items-center">
                <h2 className="font-bold min-w-[187px]">
                  Total Monthly Volume:
                </h2>
                <p className="flex-1">
                  { formatFloat(cluster?.cluster_data?.total_search_volume, 0) }
                </p>
              </div>
              <div className="flex gap-4 items-center">
                <h2 className="font-bold min-w-[187px]">
                  Total Annual Volume:
                </h2>
                <p className="flex-1">
                { cluster?.cluster_data?.annual_search_volume ? formatFloat(cluster?.cluster_data?.annual_search_volume, 0) : "-" }
                </p>
              </div>
            </div>

            <div className="page-section w-full p-4 space-y-3 bg-[#0561B7] bg-opacity-10">
            <div className="flex gap-4 items-baseline">
                <h2 className="font-bold min-w-[187px]">Most Searched:</h2>
                <p className="flex-1">
                  { Array.isArray(cluster?.cluster_data?.visualizations?.keywords) &&
                      cluster.cluster_data.visualizations.keywords.length > 0
                      ? cluster.cluster_data.visualizations.keywords[0].value 
                      : "N/A" 
                  }
                </p>
              </div>
              <div className="flex gap-4 items-center">
                <h2 className="font-bold min-w-[187px]">Highest Difficulty:</h2>
                <p className="flex-1">
                { formatPercent(cluster?.cluster_data?.most_difficult_diff?.max_kwd_value, 0) } - { cluster?.cluster_data?.most_difficult_diff?.keyword || '-' }
                </p>
              </div>
              <div className="flex gap-4 items-center">
                <h2 className="font-bold min-w-[187px]">Avg. Difficulty:</h2>
                <p className="flex-1">
                  { formatPercent(cluster?.cluster_data?.avg_diff, 0) }
                </p>
              </div>
              <div className="flex gap-4 items-center">
                <h2 className="font-bold min-w-[187px]">Highest CPC:</h2>
                <p className="flex-1">
                { formatCurrency(cluster?.cluster_data?.most_expensive_cpc?.max_cpc_value, 2) } - { cluster?.cluster_data?.most_expensive_cpc?.keyword || '-' }
                </p>
              </div>
              <div className="flex gap-4 items-center">
                <h2 className="font-bold min-w-[187px]">Avg. CPC:</h2>
                <p className="flex-1">
                  { formatCurrency(cluster?.cluster_data?.avg_cpc) }
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section">
        <h2 className="section-header">Intent</h2>

        <div className="cluster-summar p-6 space-y-6">
          <ol className="grid md:grid-cols-2 gap-x-4 md:gap-x-24 gap-y-4 md:gap-y-6 w-full">
            {cluster.cluster_data?.search_motivations?.map((reason, index) => (
              <li key={index}>
                <h3 className="font-bold text-lg">
                  { reason.title }
                </h3>
                <p className="">{reason.description}</p>
              </li>
            ))}
          </ol>
        </div>
      </section>

      <section className="page-section">
        <div>
          <h2 className="section-header">Data Visualizations</h2>
          <ChartSlider>
            <SearchVolMonth data={cluster.cluster_data?.total_search_volume_by_month} />
            <SearchVolBubble data={cluster.cluster_data?.visualizations?.search_volume_by_type} />
            <PaidSERP data={cluster.cluster_data?.visualizations?.paid_serp} />
            <ClusterSERPFeatures data={cluster.cluster_data?.visualizations?.serp_features} />
          </ChartSlider>
        </div>

        <div className="overflow-x-auto">
          <ClusterTable
            data={cluster.cluster_data?.visualizations?.keywords}
            profileId={profileId}
            history={history}
          />
        </div>
      </section>
    </div>
  </>;
}

export default Cluster;

