// react
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "../../utils/ThemeManager.jsx";

// highcharts
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more"; //needed for dumbbell chart and waterfall
import highchartsVenn from "highcharts/modules/venn"; //module for venn chart
import highchartsFunnel from "highcharts/modules/funnel"; //module for funnel chart
import highchartsHistogram from "highcharts/modules/histogram-bellcurve.js"; //module for histogram chart
import highchartsDumbbell from "highcharts/modules/dumbbell.js"; //module for dumbbell chart
import highchartsAccessibility from "highcharts/modules/accessibility";

import ChartDashboard from "../../components/ChartDashboard/ChartDashboard.jsx";
import DynamicCrumbs from "../../components/Breadcrumbs/DynamicCrumbs.jsx";
import Loading from "../../components/Loading/Loading.jsx";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay.jsx";

import useProject from "../../hooks/useProject.js";

import "./ACNUDashboard.css";

// mock data
import { useACNUData } from "./tempHooks.js";
import { generateGradientColors } from "../../utils/chartUtils.js";

highchartsAccessibility(Highcharts);
// highchartsExporting(Highcharts);
highchartsVenn(Highcharts);
highchartsFunnel(Highcharts);
highchartsHistogram(Highcharts);
highchartsMore(Highcharts);
highchartsDumbbell(Highcharts);

const ACNUDashboard = () => {
  const { theme } = useTheme();
  const { projectId } = useParams();
  const { project, loading, error } = useProject(projectId);
  const acnuData = useACNUData();

  let config = generateConfig(acnuData, theme || "dark");

  // Update chart options on theme change
  useEffect(() => {
    // Set global chart options
    if(theme) {
      config = generateConfig(acnuData, theme);
    }

  }, [theme]);

  if(loading) {
    return <Loading />;
  }

  if(error) {
    return (
      <div className="page-base space-y-4 md:space-y-8">
        <DynamicCrumbs current="ACNU Dashboard" />
        <ErrorDisplay error={error} />
      </div>
    );
  }

  return <div className="acnu-dashboard-page page-base">
    <section className="page-section">
      <h1 className="section-header">{project && project.name}</h1>

      <ChartDashboard config={config} id="acnu-dashboard" />
    </section>
  </div>;
};

export default ACNUDashboard;

const generateConfig = (acnuData, theme) => {
  return {
    gui: {
      layouts: [
        {
          id: "acnu-dashboard",
          rows: [
            // -------------- ROW 0 -------------- //
            // ------------ sunburst ------------- //
            {
              id: "acnu-row-00",
              cells: [
                {
                  id: "acnu-cell-sunburst",
                },

              ],
            },
            {
              id: "acnu-row-0",
              cells: [
                {
                  id: "acnu-cell-heat-map",
                },
              ],
            },
            // -------------- ROW 1 -------------- //
            // ----------- screen time ----------- //
            {
              id: "acnu-row-1",
              cells: [
                {
                  id: "acnu-cell-screen-time",
                },
              ],
            },
            // -------------- ROW 2 -------------- //
            // ----------- kickout zone ---------- //
            {
              id: "acnu-row-2",
              cells: [
                {
                  id: "acun-cell-kickout-zone",
                },
              ],
            },
            // -------------- ROW 3 -------------- //
            // ---- pregnant smoking alcohol ---- //
            {
              id: "acnu-row-3",
              cells: [
                {
                  id: "acnu-cell-pregnant",
                  responsive: {
                    small: {
                      width: "100%",
                    },
                    medium: {
                      width: "33.3%",
                    },
                    large: {
                      width: "33.3%",
                    },
                  },
                },
                {
                  id: "acnu-cell-smoking",
                  responsive: {
                    small: {
                      width: "100%",
                    },
                    medium: {
                      width: "33.3%",
                    },
                    large: {
                      width: "33.3%",
                    },
                  },
                },
                {
                  id: "acnu-cell-alcohol",
                  responsive: {
                    small: {
                      width: "100%",
                    },
                    medium: {
                      width: "33.3%",
                    },
                    large: {
                      width: "33.3%",
                    },
                  },
                },
              ],
            },
            // -------------- ROW 4 -------------- //
            // ---- cardiac total cholesterol ---- //
            {
              id: "acnu-row-4",
              cells: [
                {
                  id: "acnu-cell-cardiac",
                  responsive: {
                    small: {
                      width: "100%",
                    },
                    medium: {
                      width: "50%",
                    },
                    large: {
                      width: "50%",
                    },
                  },
                },
                {
                  id: "acnu-cell-total-cholesterol",
                  responsive: {
                    small: {
                      width: "100%",
                    },
                    medium: {
                      width: "50%",
                    },
                    large: {
                      width: "50%",
                    },
                  },
                },
              ],
            },
            // -------------- ROW 5 -------------- //
            // ----------- kickout funnel -------- //
            {
              id: "acnu-row-5",
              cells: [
                {
                  id: "acnu-cell-kickout-funnel",
                },
              ],
            },
            // -------------- ROW 6 -------------- //
            // ----------- age range ------------- //
            {
              id: "acnu-row-6",
              cells: [
                {
                  id: "acnu-cell-age-range",
                },
              ],
            },
            // -------------- ROW 7 -------------- //
            // ------------- reorder ------------- //
            {
              id: "acnu-row-7",
              cells: [
                {
                  id: "acnu-cell-reorder",
                  responsive: {
                    small: {
                      width: "100%",
                    },
                    medium: {
                      width: "60%",
                    },
                    large: {
                      width: "60%",
                    },
                  },
                },
                {
                  id: "acnu-cell-other-statin",
                  responsive: {
                    small: {
                      width: "100%",
                    },
                    medium: {
                      width: "40%",
                    },
                    large: {
                      width: "40%",
                    },
                  },
                },
              ],
            },
            // -------------- ROW 8 -------------- //
            // --------------- age --------------- //
            {
              id: "acnu-row-8",
              cells: [
                {
                  id: "acnu-cell-age-scatter",
                },
              ],
            },
            // -------------- ROW 9 -------------- //
            // -------------- beeswarm -------------- //
            {
              id: "acnu-row-9",
              cells: [
                {
                  id: "acnu-cell-beeswarm",
                },
              ],
            },
            // -------------- ROW 10 -------------- //
            // ------------- histogram ------------ //
            {
              id: "acnu-row-10",
              cells: [
                {
                  id: "acnu-cell-cholesterol-histogram",
                },
              ],
            },
            // -------------- ROW 11 -------------- //
            // ----------- device usage ----------- //
            {
              id: "acnu-row-11",
              cells: [
                {
                  id: "acnu-cell-device-usage-spline",
                },
              ],
            },
            // -------------- ROW 12 -------------- //
            // ------ before after dumbbell ------- //
            {
              id: "acnu-row-12",
              cells: [
                {
                  id: "acnu-cell-before-after-dumbbell-direction",
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        cell: "acnu-cell-sunburst",
        type: "Highcharts",
        chartOptions: getSunburstOptions(acnuData[0], theme),
      },
      {
        cell: "acnu-cell-heat-map",
        type: "Highcharts",
        chartOptions: getHeatmapOptions(acnuData[1], theme),
      },
      {
        cell: "acnu-cell-screen-time",
        type: "Highcharts",
        chartOptions: getScreenTimeOptions(acnuData[2], theme),
      },
      {
        cell: "acun-cell-kickout-zone",
        type: "Highcharts",
        chartOptions: getBarOptions(acnuData[3], theme),
      },
      {
        cell: "acnu-cell-pregnant",
        type: "Highcharts",
        chartOptions: getPieOptions(acnuData[4], theme),
      },
      {
        cell: "acnu-cell-smoking",
        type: "Highcharts",
        chartOptions: getPieOptions(acnuData[5], theme),
      },
      {
        cell: "acnu-cell-alcohol",
        type: "Highcharts",
        chartOptions: getPieOptions(acnuData[6], theme),
      },
      {
        cell: "acnu-cell-cardiac",
        type: "Highcharts",
        chartOptions: getColumnOptions(acnuData[7], theme),
      },
      {
        cell: "acnu-cell-total-cholesterol",
        type: "Highcharts",
        chartOptions: getBlankInputOptions(acnuData[8], theme),
      },
      {
        cell: "acnu-cell-kickout-funnel",
        type: "Highcharts",
        chartOptions: getFunnelOptions(acnuData[9], theme),
      },
      {
        cell: "acnu-cell-age-range",
        type: "Highcharts",
        chartOptions: getColumnRangeOptions(acnuData[10], theme),
      },
      {
        cell: "acnu-cell-reorder",
        type: "Highcharts",
        chartOptions: getLineOptions(acnuData[11], theme),
      },
      {
        cell: "acnu-cell-other-statin",
        type: "Highcharts",
        chartOptions: getVennOptions(acnuData[12], theme),
      },
      {
        cell: "acnu-cell-age-scatter",
        type: "Highcharts",
        chartOptions: getScatterOptions(acnuData[13], theme),
      },
      {
        cell: "acnu-cell-cholesterol-histogram",
        type: "Highcharts",
        chartOptions: getHistoOptions(acnuData[14], theme),
      },
      {
        cell: "acnu-cell-device-usage-spline",
        type: "Highcharts",
        chartOptions: getSplineOptions(acnuData[15], theme),
      },
      {
        cell: "acnu-cell-before-after-dumbbell-direction",
        type: "Highcharts",
        chartOptions: getDumbbellDirectionOptions(acnuData[16], theme),
      },
    ],
  };
}

/* CHART OPTIONS FUNCTIONS
   this is where api data gets combined with chart options
========================================== */
// returns the options for a yes/no chart
function getPieOptions({ chart, title, subtitle, series }, theme) {
  const colors = {
    dark: generateGradientColors('#FFFFFF', '#020021', series.length),
    light: generateGradientColors('#0561B7', '#C0D7ED', series.length),
  }

  return {
    colors: colors[theme],
    chart,
    title,
    subtitle,
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          distance: -50,
          format: "{point.percentage:.1f}%",
          style: {
            fontWeight: "bold",
            fontSize: "1.25rem",
          },
        },
      },
    },
    series: [
      {
        data: series.map((d) => {
          return {
            name: d.name,
            y: d.y,
          };
        }),
      },
    ],
  };
}

// returns the options for a multiselect chart
function getColumnOptions({ chart, title, subtitle, xAxis, yAxis, series }, theme) {
  // generate chart colors from series.length
  const colors = {
    dark: generateGradientColors('#FFFFFF', '#020021', series.length),
    light: generateGradientColors('#0561B7', '#C0D7ED', series.length),
  }

  return {
    chart,
    colors: colors[theme],
    title,
    subtitle,
    xAxis,
    yAxis,
    legend: {
      align: "center",
      verticalAlign: "bottom",
      itemStyle: {
        color: "white",
        textOutline: "1px solid #000000",
      },
      itemHoverStyle: {
        color: Highcharts.getOptions().colors[0],
      },
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}; font-weight: bold;">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          format: "{point.y:.0f}",
        },
      },
    },
    series: series.map((s, i) => {
      return {
        name: s.name,
        data: s.data,
      };
    }),
  };
}

// returns the options for a blank input chart
function getBlankInputOptions({ chart, title, subtitle, xAxis, yAxis, series }, theme) {
  return {
    chart,
    title,
    subtitle,
    xAxis,
    yAxis,
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}; font-weight: bold;">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          format: "{point.y:.0f}",
        },
      },
    },
    series: [
      {
        type: "boxplot",
        name: series[0].name || "",
        color: theme === 'dark' ? '#0561B7' : '#020021',
        fillColor: "#0561B756",
        lineColor: "#FFFFFF",
        stemColor: theme === 'dark' ? '#FFFFFF' : '#020021',    // The vertical line color
        whiskerColor: theme === 'dark' ? '#FFFFFF' : '#020021', // Whisker line color
        medianColor: theme === 'dark' ? '#FFFFFF' : '#020021',   // Median line color if applicable
        data: series[0].data || [],
        tooltip: {
          headerFormat: "<em>{point.key}</em><br/>",
        },
      },
      {
        type: "scatter",
        name: series[1].name || "",
        color: '#3AE500',
        data: series[1].data || [],
        marker: {
          fillColor: "#FFFFFF56",
          lineWidth: 1,
          lineColor: "#3AE500",
        },
        tooltip: {
          pointFormat: "{point.y}",
        },
      },
      {
        type: "scatter",
        name: series[2].name || "",
        color: '#FFFFFF',
        data: series[2].data || [],
        marker: {
          fillColor: "#FFFFFF56",
          lineWidth: 2,
          lineColor: "#FFFFFF",
        },
        tooltip: {
          headerFormat: "<em>{point.key} Avg</em><br/>",
          pointFormat: "{point.y}",
        },
      },
    ],
  };
}

// returns the options for a screen time chart
function getScreenTimeOptions({ id, title, subtitle, xAxis, yAxis, series }, theme) {
  const colors = {
    dark: ["#0561B756", "#FFFFFF"],
    light: ["#0561B756"],
  }
  
  return {
    colors: colors[theme],
    chart: {
      id: id,
      type: "column",
      styledMode: false,
    },
    title: {
      text: title,
    },
    subtitle: {
      text: subtitle,
    },
    xAxis: {
      categories: xAxis.categories,
      accessibility: xAxis.accessibility,
    },
    yAxis: {
      min: yAxis.min || 0,
      title: {
        text: yAxis.title.text || "",
      },
    },
    tooltip: {
      valueSuffix: "s",
    },
    series: [
      {
        type: "column",
        name: series[0].name,
        data: series[0].data,
      },
      {
        type: "errorbar",
        name: series[1].name,
        data: series[1].data,
        stemColor: theme === 'dark' ? '#3AE500' : '#020021',    // The vertical line color
        whiskerColor: theme === 'dark' ? '#3AE500' : '#020021', // Whisker line color
        medianColor: theme === 'dark' ? '#3AE500' : '#020021',   // Median line color if applicable
        // stemWidth: 2,    // increase stroke width of stem line
        // whiskerWidth: 2, // increase stroke width of whisker line
        // medianWidth: 2   // increase stroke width of median line (if visible)

      },
    ],
  };
}

// returns the options for a column range chart
function getColumnRangeOptions({ chart, title, subtitle, xAxis, yAxis, series }, theme) {
  const colors = {
    dark: generateGradientColors('#FFFFFF', '#020021', series[0].data.length),
    light: generateGradientColors('#0561B7', '#C0D7ED', series[0].data.length),
  }

  return {
    colors: colors[theme],
    chart,
    title,
    subtitle,
    xAxis,
    yAxis,
    plotOptions: {
      columnrange: {
        borderRadius: "50%",
        dataLabels: {
          enabled: true,
          format: "{y}",
        },
        color: "#0561B756",
      },
    },

    legend: {
      enabled: false,
    },
    series,
  };
}

// returns the options for a bar chart
function getBarOptions({ chart, title, subtitle, xAxis, yAxis, series }, theme) {
  const colors = {
    dark: ['#FFFFFF', '#FF7347'],
    light: ['#0561B7', '#C0D7ED'],
  }
  
  return {
    colors: colors[theme],
    chart,
    title,
    subtitle,
    xAxis,
    yAxis,

    tooltip: {
      valueSuffix: " ",
    },

    plotOptions: {
      bar: {
        borderRadius: "50%",
        dataLabels: {
          enabled: true,
          color: theme === 'dark' ? '#3AE500' : '#020021',
          // increase font size to 1.25rem
          style: {
            fontSize: "1.25rem",
          },
        },
        groupPadding: 0.1,
      },
    },
    legend: {
      enabled: false,
    },

    series: series,
  };
}

// returns the options for a line chart
function getLineOptions({ chart, title, subtitle, xAxis, yAxis, series }, theme) {
  return {
    chart,
    title,
    subtitle,
    xAxis,
    yAxis,
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "1rem",
          },
        },
        enableMouseTracking: false,
        color: theme === 'dark' ? '#3AE500' : '#020021',
      },
    },

    legend: {
      enabled: false,
    },

    series: series,
  };
}

// returns the options for a venn chart
function getVennOptions({ chart, title, subtitle, series }, theme) {
  const colors = {
    dark: generateGradientColors('#FFFFFF', '#020021', series.length),
    light: generateGradientColors('#0561B7', '#C0D7ED', series.length),
  }
  return {
    colors: colors[theme],
    chart,
    title,
    subtitle,
    series: [
      {
        type: "venn",
        data: series.map((d, i) => {
          return {
            sets: d.sets,
            value: d.value,
          };
        }),
      },
    ],
  };
}

// returns the options for a funnel chart
function getFunnelOptions({ chart, title, subtitle, series }, theme) {
  const colors = {
    dark: generateGradientColors('#FCFCFC', '#020021', series[0].data.length),
    light: generateGradientColors('#C4D6EB', '#0561B7', series[0].data.length),
  }
  return {
    colors: colors[theme],
    chart,
    title,
    subtitle,
    legend: {
      enabled: true, // Enable the legend
      layout: "vertical",
      align: "right", // Align to the right
      verticalAlign: "middle", // Center vertically
      padding: 10 // Add some padding for aesthetics
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false // Remove data labels
        },
        center: ["50%", "55%"],
      }
    },
    series,
  };
}

// returns the options for a jitter plot chart
function getScatterOptions({ chart, title, subtitle, xAxis, yAxis, series }, theme) {
  const colors = {
    dark: generateGradientColors('#FFFFFF', '#0561B7', series.length),
    light: generateGradientColors('#0561B7', "#020021", series.length),
  }

  return {
    colors: colors[theme],
    chart,
    title,
    subtitle,
    xAxis,

    yAxis: {
      ...yAxis,
      plotLines: [
        {
          ...yAxis.plotLines[0],
          color: theme === 'dark' ? "#3AE500" : "#020021",
          width: 1,
        },
        {
          ...yAxis.plotLines[1],
          color: theme === 'dark' ? "#3AE500" : "#020021",
          width: 1,
        },
      ],
      plotBands: {
        from: yAxis.plotLines[1].value,
        to: yAxis.plotLines[0].value,
        color: theme === 'dark' ? "#FFFFFF56" : "#0561B756",
        label: {

          text: "Qualification Range",
          verticalAlign: "top",
          align: "left",
          x: 10 /*adjust how close the text to the border, horizontally*/,
          y: 20 /*adjust how close the text to the border, vertically*/,
          zIndex: 997,
          style: {color: theme === 'dark' ? "#3AE500" : "#0561B7"},
        },
      },
    },

    tooltip: {
      valueSuffix: " ",
    },

    plotOptions: {
      scatter: {
        showInLegend: false,
        jitter: {
          x: 0.24,
          y: 0,
        },
        marker: {
          radius: 2,
          symbol: "circle",
        },
        tooltip: {
          pointFormat: "Measurement: {point.y:.3f}",
        },
        zIndex: 1,
      },
    },

    legend: {
      enabled: false,
    },

    series: series,
  };
}

// returns the options for a jitter plot chart, shapley values
function getBeesWarmOptions({ chart, title, subtitle, xAxis, yAxis, series }, theme) {
  const colors = {
    dark: generateGradientColors('#FFFFFF', '#0561B7', series.length),
    light: generateGradientColors('#0561B7', "#020021", series.length),
  }

  return {
    colors: colors[theme],
    chart,
    title,
    subtitle,
    xAxis,
    yAxis,
    plotOptions: {
      scatter: {
        showInLegend: false,
        jitter: {
          x: 0.24,
          y: 0,
        },
        marker: {
          radius: 2,
          symbol: "circle",
        },
        tooltip: {
          pointFormat: "Measurement: {point.y:.3f}",
        },
        zIndex: 1,
      },
    },

    legend: {
      enabled: false,
    },

    series: series,
  };
}

// returns the options for histogram with scatter plot
function getHistoOptions({ chart, title, subtitle, xAxis, yAxis, series }, theme) {
  const colors = {
    dark: generateGradientColors('#FFFFFF', '#020021', series.length),
    light: generateGradientColors('#0561B7', '#C0D7ED', series.length),
  }

  return {
    colors: colors[theme],
    chart,
    title,
    subtitle,
    xAxis,
    yAxis,
    plotOptions: {
      histogram: {
        color: theme === 'dark' ? "#0561B756" : "#02002156",
        accessibility: {
          point: {
            valueDescriptionFormat:
              "{index}. {point.x:.1f} to {point.x2:.1f}, {point.y}.",
          },
        },
      },

      scatter: {
        marker: {
          radius: 3,
          symbol: "circle",
        },
      },
    },

    series: series,
  };
}

// returns the options for a spline chart
function getSplineOptions({ chart, title, subtitle, xAxis, yAxis, tooltip, series }, theme) {
  const colors = {
    dark: generateGradientColors('#FFFFFF', '#0561B7', series.length),
    light: generateGradientColors('#0561B7', '#C0D7ED', series.length),
  }

  return {
    colors: colors[theme],
    chart,
    title,
    subtitle,
    xAxis,
    yAxis,
    tooltip: {
      valueSuffix: "%",
      stickOnContact: true,
    },
    tooltip,
    plotOptions: {
      series: {
        point: {
          events: {
            click: function () {
              window.location.href = this.series.options.website;
            },
          },
        },
        cursor: "pointer",
        lineWidth: 2,
      },
    },

    legend: {
      enabled: true,
      itemWidth: 150,
    },
    series,
  };
}

// returns the options for a dumbbell chart with direction
function getDumbbellDirectionOptions({ chart, title, subtitle, xAxis, yAxis, tooltip, series }, theme) {
  const colors = {
    dark: generateGradientColors('#FFFFFF', '#0561B7', series.length),
    light: generateGradientColors('#0561B7', '#C0D7ED', series.length),
  }
  
  return {
    colors: colors[theme],
    chart,
    title,
    subtitle,
    xAxis,
    yAxis,
    tooltip,
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        connectorWidth: 3,
        marker: {
          radius: 5,
          states: {
            hover: {
              lineWidth: 0,
            },
          },
        },
        dataLabels: {
          enabled: true,
          crop: false,
          overflow: "allow",
        },
      },
    },
    series,
  };
}

// returns the options for a blank input chart
function getSunburstOptions({ id, type, title, subtitle, series }, theme) {
  const colors = {
    dark: ['#FFFFFF', '#020021'],
    light: ['#0561B7', '#C0D7ED'],
  }
  return {
    colors: colors[theme],
    chart: {
      id: id,
      type: type,
      styledMode: false,
      height: 650
    },

    title: {
      text: title || "",
    },

    subtitle: {
      text: subtitle || "",
    },
    plotOptions: {
      series: {
        animation: false
      }
    },


    series: [
      {
        type: type,
        data: series,
        name: "Root",
        color: ["transparent"].concat(Highcharts.getOptions().colors[0]), // let the center circle be transparent
        allowDrillToNode: true,
        borderRadius: 3,
        cursor: "pointer",
        dataLabels: {
          format: "{point.name}",
          filter: {
            property: "innerArcLength",
            operator: ">",
            value: 16,
          },
        },
        levels: [
          {
            level: 1,
            levelIsConstant: false,
            dataLabels: {
              filter: {
                property: "outerArcLength",
                operator: ">",
                value: 64,
              },
            },
          },
          {
            level: 2,
            colorByPoint: true,
          },
          {
            level: 3,
            colorVariation: {
              key: "brightness",
              to: -0.1,
            },
          },
          {
            level: 4,
            colorVariation: {
              key: "brightness",
              to: 0.5,
            },
          },
        ],
      },
    ],
  };
}

// returns the options for a heatmap chart
function getHeatmapOptions({ id, type, title, subtitle, xAxis, yAxis, series }, theme) {
  const colors = {
    dark: ['#0561B7', '#FFFFFF'],
    light: ['#0561B7', '#C0D7ED'],
  }

  return {
    id: id,
    colors: colors[theme],
    chart: {
      type: type,
      styledMode: false,
    },

    title: {
      text: title,
    },

    subtitle: {
      text: subtitle,
    },

    xAxis: {
      categories: xAxis.categories,
    },

    yAxis: {
      categories: yAxis.categories,
      title: null,
      reversed: true,
    },

    colorAxis: {
      min: -1,
      minColor: theme === 'dark' ? "#020021" : "#FFFFFF",
      maxColor: colors[theme][1],
      labels: {
        style: {
          color: theme === 'dark' ? "#FFFFFF" : "#020021",
        }
      }
    },

    legend: {
      align: "right",
      layout: "vertical",
      margin: 0,
      verticalAlign: "top",
      y: 40,
      symbolHeight: 280,
    },

    series: [
      {
        name: series.name,
        borderWidth: 1,
        data: series[0].data || [],
        dataLabels: {
          enabled: true,
        },
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            yAxis: {
              labels: {
                format: "{substr value 0 1}",
              },
            },
          },
        },
      ],
    },
  };
}
