const InputField = ({ id, className, name, label, value, onChange, disabled = false }) => {
  return <div className={`form-group ${className}`}>
    <label htmlFor={id}>{label}</label>
    <input 
      type="text" 
      id={id} 
      name={name} 
      value={value || ''} 
      onChange={onChange} 
      disabled={disabled} 
    />
  </div>
};

export default InputField;