// these will mimic the return of api data
// should not have any layout or styling

//generate dummy data for jitter plot
const getTestData = (x) => {
  const off = 0.2 + 0.2 * Math.random();
  return new Array(200)
    .fill(1)
    .map(() => [x, off + (Math.random() - 0.5) * (Math.random() - 0.5)]);
};

// dummy data for total cholesterol
const tc_data = [
  3.5, 3, 3.2, 3.1, 3.6, 3.9, 3.4, 3.4, 2.9, 3.1, 3.7, 3.4, 3, 3, 4, 4.4, 3.9,
  3.5, 3.8, 3.8, 3.4, 3.7, 3.6, 3.3, 3.4, 3, 3.4, 3.5, 3.4, 3.2, 3.1, 3.4, 4.1,
  4.2, 3.1, 3.2, 3.5, 3.6, 3, 3.4, 3.5, 2.3, 3.2, 3.5, 3.8, 3, 3.8, 3.2, 3.7,
  3.3, 3.2, 3.2, 3.1, 2.3, 2.8, 2.8, 3.3, 2.4, 2.9, 2.7, 2, 3, 2.2, 2.9, 2.9,
  3.1, 3, 2.7, 2.2, 2.5, 3.2, 2.8, 2.5, 2.8, 2.9, 3, 2.8, 3, 2.9, 2.6, 2.4, 2.4,
  2.7, 2.7, 3, 3.4, 3.1, 2.3, 3, 2.5, 2.6, 3, 2.6, 2.3, 2.7, 3, 2.9, 2.9, 2.5,
  2.8, 3.3, 2.7, 3, 2.9, 3, 3, 2.5, 2.9, 2.5, 3.6, 3.2, 2.7, 3, 2.5, 2.8, 3.2,
  3, 3.8, 2.6, 2.2, 3.2, 2.8, 2.8, 2.7, 3.3, 3.2, 2.8, 3, 2.8, 3, 2.8, 3.8, 2.8,
  2.8, 2.6, 3, 3.4, 3.1, 3, 3.1, 3.1, 3.1, 2.7, 3.2, 3.3, 3, 2.5, 3, 3.4, 3,
];

// dummy data afer for "before vs after medince usage"
const data = [
  {
    name: "Total Cholesterol",
    previous: 248,
    current: 222,
  },
  {
    name: "LDL",
    previous: 157,
    current: 178,
  },
  {
    name: "HDL",
    previous: 66,
    current: 79,
  },
  {
    name: "Risk Score",
    previous: 20,
    current: 15,
  },
  {
    name: "metric 5",
    previous: 104,
    current: 73,
  },
  {
    name: "metric 6",
    previous: 101,
    current: 70,
  },
  {
    name: "metric 7",
    previous: 51,
    current: 50,
  },
  {
    name: "metric 8",
    previous: 41,
    current: 49,
  },
  {
    name: "metric 9",
    previous: 39,
    current: 48,
  },
  {
    name: "metric 10",
    previous: 62,
    current: 47,
  },
  {
    name: "metric 11",
    previous: 54,
    current: 42,
  },
  {
    name: "metric 12",
    previous: 19,
    current: 41,
  },
  {
    name: "metric 13",
    previous: 49,
    current: 40,
  },
  {
    name: "metric 14",
    previous: 55,
    current: 36,
  },
].map((dataPoint) => {
  const isIncrease = dataPoint.previous < dataPoint.current;
  return {
    ...dataPoint,
    low: isIncrease ? dataPoint.previous : dataPoint.current,
    high: isIncrease ? dataPoint.current : dataPoint.previous,
  };
});

// Separate the data into increasing and decreasing series. before vs after medicine usage
const increasingData = [],
  decreasingData = [];
data.forEach((dataPoint, index) => {
  const isIncrease = dataPoint.previous < dataPoint.current,
    transformedDataPoint = {
      ...dataPoint,
      x: index,
    };

  if (isIncrease) {
    increasingData.push(transformedDataPoint);
  } else {
    decreasingData.push(transformedDataPoint);
  }
});

// add new chart data objects to this array
const useACNUData = () => {
  return [
    // 0: sunburst (comined demographics)
    {
      id: "demographics",
      type: "sunburst",
      title: "Demographics",
      subtitle: "Jan 2024",
      seriesKey: "# People",
      series: [
        {
          id: "0.0",
          parent: "",
          name: "Demographics",
          level: 0,
        },
        { id: "1.1", parent: "0.0", name: "Male" },
        { id: "1.2", parent: "0.0", name: "Female" },

        { id: "2.1", parent: "1.1", name: "20 - 29" },
        { id: "2.2", parent: "1.1", name: "30 - 39" },
        { id: "2.3", parent: "1.1", name: "40 - 49" },
        { id: "2.4", parent: "1.1", name: "50 - 59" },
        { id: "2.5", parent: "1.1", name: "60 - 69" },
        { id: "2.6", parent: "1.1", name: "70+" },

        { id: "2.7", parent: "1.2", name: "20 - 29" },
        { id: "2.8", parent: "1.2", name: "30 - 39" },
        { id: "2.9", parent: "1.2", name: "40 - 49" },
        { id: "2.10", parent: "1.2", name: "50 - 59" },
        { id: "2.11", parent: "1.2", name: "60 - 69" },
        { id: "2.12", parent: "1.2", name: "70+" },

        { id: "3.1", parent: "2.1", name: "White", value: 1040 },
        { id: "3.2", parent: "2.1", name: "Black", value: 5000 },
        { id: "3.3", parent: "2.1", name: "Hispanic", value: 450 },
        { id: "3.4", parent: "2.1", name: "Asian", value: 200 },
        { id: "3.5", parent: "2.1", name: "South Asian", value: 200 },
        { id: "3.6", parent: "2.1", name: "Other", value: 100 },

        { id: "3.7", parent: "2.2", name: "White", value: 1049 },
        { id: "3.8", parent: "2.2", name: "Black", value: 104 },
        { id: "3.9", parent: "2.2", name: "Hispanic", value: 1049 },
        { id: "3.10", parent: "2.2", name: "Asian", value: 1049 },
        { id: "3.11", parent: "2.2", name: "South Asian", value: 1049 },
        { id: "3.12", parent: "2.2", name: "Other", value: 1048 },

        { id: "3.13", parent: "2.3", name: "White", value: 1049 },
        { id: "3.14", parent: "2.3", name: "Black", value: 1049 },
        { id: "3.15", parent: "2.3", name: "Hispanic", value: 104 },
        { id: "3.16", parent: "2.3", name: "Asian", value: 1049 },
        { id: "3.17", parent: "2.3", name: "South Asian", value: 1049 },
        { id: "3.18", parent: "2.3", name: "Other", value: 1049 },

        { id: "3.19", parent: "2.4", name: "White", value: 1049 },
        { id: "3.20", parent: "2.4", name: "Black", value: 1049 },
        { id: "3.21", parent: "2.4", name: "Hispanic", value: 104 },
        { id: "3.22", parent: "2.4", name: "Asian", value: 1049 },
        { id: "3.23", parent: "2.4", name: "South Asian", value: 1049 },
        { id: "3.24", parent: "2.4", name: "Other", value: 104 },

        { id: "3.25", parent: "2.5", name: "White", value: 1049 },
        { id: "3.26", parent: "2.5", name: "Black", value: 1049 },
        { id: "3.27", parent: "2.5", name: "Hispanic", value: 1049 },
        { id: "3.28", parent: "2.5", name: "Asian", value: 1049 },
        { id: "3.29", parent: "2.5", name: "South Asian", value: 1049 },
        { id: "3.30", parent: "2.5", name: "Other", value: 1049 },

        { id: "3.31", parent: "2.6", name: "White", value: 104 },
        { id: "3.32", parent: "2.6", name: "Black", value: 104 },
        { id: "3.33", parent: "2.6", name: "Hispanic", value: 1049 },
        { id: "3.34", parent: "2.6", name: "Asian", value: 1049 },
        { id: "3.35", parent: "2.6", name: "South Asian", value: 1049 },
        { id: "3.36", parent: "2.6", name: "Other", value: 1049 },

        { id: "3.37", parent: "2.7", name: "White", value: 1049 },
        { id: "3.38", parent: "2.7", name: "Black", value: 1049 },
        { id: "3.39", parent: "2.7", name: "Hispanic", value: 1049 },
        { id: "3.40", parent: "2.7", name: "Asian", value: 1049 },
        { id: "3.41", parent: "2.7", name: "South Asian", value: 1049 },
        { id: "3.42", parent: "2.7", name: "Other", value: 1049 },

        { id: "3.43", parent: "2.8", name: "White", value: 1049 },
        { id: "3.44", parent: "2.8", name: "Black", value: 1049 },
        { id: "3.45", parent: "2.8", name: "Hispanic", value: 1049 },
        { id: "3.46", parent: "2.8", name: "Asian", value: 1049 },
        { id: "3.47", parent: "2.8", name: "South Asian", value: 104 },
        { id: "3.48", parent: "2.8", name: "Other", value: 104 },

        { id: "3.49", parent: "2.9", name: "White", value: 104 },
        { id: "3.50", parent: "2.9", name: "Black", value: 1049 },
        { id: "3.51", parent: "2.9", name: "Hispanic", value: 1049 },
        { id: "3.52", parent: "2.9", name: "Asian", value: 1000 },
        { id: "3.53", parent: "2.9", name: "South Asian", value: 1049 },
        { id: "3.54", parent: "2.9", name: "Other", value: 1049 },

        { id: "3.55", parent: "2.10", name: "White", value: 1049 },
        { id: "3.56", parent: "2.10", name: "Black", value: 104 },
        { id: "3.57", parent: "2.10", name: "Hispanic", value: 104 },
        { id: "3.58", parent: "2.10", name: "Asian", value: 104 },
        { id: "3.59", parent: "2.10", name: "South Asian", value: 104 },
        { id: "3.60", parent: "2.10", name: "Other", value: 104 },

        { id: "3.61", parent: "2.11", name: "White", value: 1049 },
        { id: "3.62", parent: "2.11", name: "Black", value: 1049 },
        { id: "3.63", parent: "2.11", name: "Hispanic", value: 1049 },
        { id: "3.64", parent: "2.11", name: "Asian", value: 1049 },
        { id: "3.65", parent: "2.11", name: "South Asian", value: 1049 },
        { id: "3.66", parent: "2.11", name: "Other", value: 1049 },

        { id: "3.73", parent: "2.12", name: "White", value: 1049 },
        { id: "3.74", parent: "2.12", name: "Black", value: 1049 },
        { id: "3.75", parent: "2.12", name: "Hispanic", value: 1049 },
        { id: "3.76", parent: "2.12", name: "Asian", value: 1049 },
        { id: "3.77", parent: "2.12", name: "South Asian", value: 104 },
        { id: "3.78", parent: "2.12", name: "Other", value: 104 },
      ],
    },
    // 1: heatmap
    {
      id: "featureCorrelation",
      type: "heatmap",
      title: "Feature Correlation",
      subtitle: "Jan 2024",
      xAxis: {
        categories: [
          "Age",
          "Gender",
          "Race",
          "eGFR",
          "Device",
          "# Attempts",
          "More Info Use",
          "# Screens",
          "Total Time",
          "Outcome",
        ],
      },
      yAxis: {
        categories: [
          "Age",
          "Gender",
          "Race",
          "eGFR",
          "Device",
          "# Attempts",
          "More Info Use",
          "# Screens",
          "Total Time",
          "Outcome",
        ],
      },
      series: [
        {
          name: "heatmap",
          data: [
            [0, 0, 1.0],
            [0, 1, 0.19],
            [0, 2, -0.35],
            [0, 3, 0.48],
            [0, 4, -0.21],
            [0, 5, 0.81],
            [0, 6, 0.6],
            [0, 7, -0.89],
            [0, 8, 0.22],
            [0, 9, 0.1],

            [1, 0, 0.45],
            [1, 1, 1],
            [1, 2, 0.78],
            [1, 3, 0.86],
            [1, 4, 0.22],
            [1, 5, 0.32],
            [1, 6, 0.34],
            [1, 7, 0.86],
            [1, 8, 0.12],
            [1, 9, 0.39],

            [2, 0, 0.63],
            [2, 1, 0.15],
            [2, 2, 1],
            [2, 3, -0.91],
            [2, 4, -0.32],
            [2, 5, -0.78],
            [2, 6, -0.9],
            [2, 7, 0.12],
            [2, 8, 0.55],
            [2, 9, 0.4],

            [3, 0, -0.92],
            [3, 1, -0.52],
            [3, 2, -0.78],
            [3, 3, 1],
            [3, 4, -0.94],
            [3, 5, 0.35],
            [3, 6, 0.44],
            [3, 7, 0.65],
            [3, 8, 0.76],
            [3, 9, 0.35],

            [4, 0, 0.11],
            [4, 1, -0.59],
            [4, 2, 0.78],
            [4, 3, -0.24],
            [4, 4, 1],
            [4, 5, -0.85],
            [4, 6, -0.72],
            [4, 7, 0.35],
            [4, 8, 0.36],
            [4, 9, 0.11],

            [5, 0, 0.92],
            [5, 1, -0.21],
            [5, 2, 0.78],
            [5, 3, -0.8],
            [5, 4, -0.9],
            [5, 5, 1],
            [5, 6, 0.35],
            [5, 7, 0.12],
            [5, 8, 0.31],
            [5, 9, -0.76],

            [6, 0, -0.32],
            [6, 1, 0.53],
            [6, 2, 0.78],
            [6, 3, 0.51],
            [6, 4, -0.92],
            [6, 5, 0.35],
            [6, 6, 1],
            [6, 7, 0.35],
            [6, 8, 0.42],
            [6, 9, 0.35],

            [7, 0, 0.75],
            [7, 1, 0.5],
            [7, 2, 0.78],
            [7, 3, 0.7],
            [7, 4, -0.9],
            [7, 5, 0.35],
            [7, 6, 0.35],
            [7, 7, 1],
            [7, 8, 0.35],
            [7, 9, 0.35],

            [8, 0, 0.91],
            [8, 1, 0.5],
            [8, 2, 0.78],
            [8, 3, 0.65],
            [8, 4, 0.56],
            [8, 5, 0.35],
            [8, 6, 0.44],
            [8, 7, 0.36],
            [8, 8, 0.1],
            [8, 9, 0.35],

            [9, 0, 0.66],
            [9, 1, 0.5],
            [9, 2, 0.78],
            [9, 3, 0.55],
            [9, 4, -0.9],
            [9, 5, 0.35],
            [9, 6, -0.88],
            [9, 7, 0.9],
            [9, 8, 0.35],
            [9, 9, 1],
          ],
        },
      ],
    },
    // 2: screen time
    {
      id: "screen-time",
      type: "screentime",
      title: "Screen Time",
      subtitle: "Jan 2024",
      xAxis: {
        accessibility: {
          description: "Screen Time",
        },
        categories: [
          "Age / Gender",
          "Race / Ethnicity",
          "Pregnant",
          "BP",
          "eGFR",
          "Cardiac Conditions",
          "Smoking",
        ],
      },
      yAxis: {
        min: 0,
        title: {
          text: "Time (min)",
        },
      },
      series: [
        {
          name: "Avg Time",
          data: [51.1, 13.5, 5.5, 141.2, 107.6, 77.8, 10.4],
        },
        {
          name: "Time Error",
          data: [
            [48, 52],
            [10, 15],
            [5.3, 5.6],
            [138, 145],
            [95, 110],
            [76, 79],
            [10, 12],
          ],
        },
      ],
    },
    // 3: kickout zones
    {
      chart: {
        id: "kickout-zone",
        type: "bar",
        styledMode: false,
      },
      title: {
        text: "Kickout Zones",
      },
      subtitle: {
        text: "Jan 2024",
      },
      xAxis: {
        categories: [
          "Age / Gender",
          "Race / Ethnicity",
          "Pregnant",
          "BP",
          "eGFR",
          "Cardiac Conditions",
          "Smoking",
        ],
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Sessions (%)",
        },
      },
      series: [
        {
          name: "Kick-outs",
          data: [100, 90, 80, 70, 60, 50, 40],
        },
      ],
    },
    // 4: pregnant
    {
      chart: {
        id: "pregnant",
        type: 'pie',
        styledMode: false,
      },
      title: {
        text: "Pregnant",
      },
      subtitle: {
        text: "Jan 2024",
      },
      series: [
        {
          name: "Yes",
          y: 400,
        },
        {
          name: "No",
          y: 20,
        },
      ],
    },
    // 5: smoking
    {
      chart: {
        id: "smoking",
        type: 'pie',
        styledMode: false,
      },
      title: {
        text: "Smoking",
      },
      subtitle: {
        text: "Jan 2024",
      },
      series: [
        {
          name: "Yes",
          y: 238,
        },
        {
          name: "No",
          y: 422,
        },
      ],
    },
    // 6: alcohol
    {
      chart: {
        id: "alcohol",
        type: 'pie',
        styledMode: false,
      },
      title: {
        text: "Alcohol Usage",
      },
      subtitle: {
        text: "Jan 2024",
      },
      series: [
        {
          name: "Yes",
          y: 215,
        },
        {
          name: "No",
          y: 445,
        },
      ],
    },
    // 7: cardiac conditions
    {
      chart: {
        id: "cardiac-conditions",
        type: "column",
        styledMode: false,
      },
      title: {
        text: "Cardiac Conditions Over Time"
      },
      subtitle: {
        text: "Q1 2024"
      },
      xAxis: {
        categories: ["Jan", "Feb", "Mar"],
      },
      yAxis: {
        min: 0,
        title: {
          text: "# People",
        },
      },
      series: [
        {
          name: "Heart Attack",
          data: [434, 290, 307],
        },
        {
          name: "Stroke",
          data: [272, 153, 120],
        },
        {
          name: "Bypass Surgery",
          data: [55, 90, 120],
        },
        {
          name: "> 1",
          data: [2, 30, 10],
        },
        {
          name: "All",
          data: [20, 30, 12],
        },
      ],
    },
    // 8: total cholesterol
    {
      chart: {
        id: "total-cholesterol",
        type: "boxplot",
        styledMode: false,
      },
      title: {
        text: "Change in Total Cholesterol"
      },
      subtitle: {
        text: "Topamax"
      },
      xAxis: {
        categories: ["Initial", "First Reassessment"],
        title: {
          text: "",
        },
      },
      yAxis: {
        title: {
          text: "Total Cholesterol (mg/dL)",
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: "Distribution",
          data: [
            [80, 80, 70, 180, 210],
            [30, 55, 60, 70, 170],
          ],
        },
        {
          name: "Outliers",
          data: [
            [0, 10],
            [0, 20],
            [0, 25],
            [1, 10],
            [1, 11],
            [1, 2],
          ],
        },
        {
          name: "Avg",
          data: [
            [0, 77],
            [1, 65],
          ],
        },
      ],
    },
    // 9: Kicked out & Came back
    {
      chart: {
        id: "kick-out-come-back",
        type: "funnel",
        styledMode: false,
      },
      title: {
        text: "Kick Out & Came Back"
      },
      subtitle: {
        text: "Jan 2024"
      },
      series: [{
        showInLegend: true,
        data: [{
          name: "Kicked out on Screen Total Cholesterol",
          y: 15654,
        },
        {
          name: "Came back & Tried Again",
          y: 4064,
        },
        {
          name: "Got OK to Use",
          y: 1987,
        }],
      }],
    },
    // 10: column range
    {
      chart: {
        id: "column-range",
        type: "columnrange",
        styledMode: false,
      },
      title: {
        text: "Column Range Plot example",
      },

      subtitle: {
        text: "Jan 2024 ",
      },

      xAxis: {
        title: {
          text: "Age Bucket",
        },
        categories: [
          "40-44",
          "45-49",
          "50-54",
          "55-59",
          "60-64",
          "65-69",
          "70-74",
          "75-79",
        ],
      },

      yAxis: {
        title: {
          text: "Measurement",
        },
      },

      series: [
        {
          name: "Measurement",
          data: [
            [-13.9, 5.2],
            [-16.7, 10.6],
            [-4.7, 11.6],
            [-4.4, 16.8],
            [-2.1, 27.2],
            [5.9, 29.4],
            [6.5, 29.1],
            [4.7, 25.4],
          ],
        },
      ],
    },
    // 11: reorder timing
    {
      chart: {
        id: "reorder-timing",
        type: "line",
        styledMode: false,
      },
      title: {
        text: "Reorder Timing"
      },
      subtitle: {
        text: "Jan 2024"
      },
      xAxis: {
        categories: ["1", "2", "3", "4", "5"],
        title: {
          text: "# Reorders",
        },
      },
      yAxis: {
        title: {
          text: "% people reorder before run out medicine",
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: "Reorder",
          data: [16.0, 18.2, 23.1, 27.9, 32.2],
        },
      ],
    },
    // 12: other statin usage
    {
      chart: {
        id: "other-statin-usage",
        type: "venn",
        styledMode: false,
      },
      title: {
        text: "Other Statin Screen"
      },
      subtitle: {
        text: "Jan 2024"
      },

      series: [
        {
          sets: ["select True"],
          value: 2,
        },
        {
          sets: ["Select False"],
          value: 2,
        },
        {
          sets: ["select True", "Select False"],
          value: 1,
          name: "multiple answers",
        },
      ],
    },
    // 13: age range
    {
      chart: {
        id: "age-range",
        type: "scatter",
        styledMode: false,
      },
      title: { 
        text: "Scatter Chart with Jitter"
      },
      subtitle: { 
        text: "Jan 2024"
      },
      xAxis: {
        title: {
          text: "Age Bucket",
        },
        categories: [
          "40-44",
          "45-49",
          "50-54",
          "55-59",
          "60-64",
          "65-69",
          "70-74",
          "75-79",
        ],
      },

      yAxis: {
        title: {
          text: "Measurement",
        },
        plotLines: [
          {
            value: 0.21
          },
          {
            value: 0.35
          }
        ]
      },

      series: [
        {
          name: "Age 40-45",
          data: getTestData(0),
        },
        {
          name: "Age 45-50",
          data: getTestData(1),
        },
        {
          name: "Age 50-55",
          data: getTestData(2),
        },
        {
          name: "Age 55-60",
          data: getTestData(3),
        },
        {
          name: "Age 60-65",
          data: getTestData(4),
        },
        {
          name: "Age 65-70",
          data: getTestData(5),
        },
        {
          name: "Age 70-75",
          data: getTestData(6),
        },
      ],
    },
    // 14: total cholesterol distribution
    {
      chart: {
        id: "cholesterol-distribution",
        type: "histochart",
        styledMode: false,
      },
      title: {
        text: "Total Cholesterol Value Distribution"
      },
      subtitle: {
        text: "Initial Assessment"
      },
      xAxis: [
        {
          title: { text: "Data" },
          alignTicks: false,
        },
        {
          title: { text: "Frequency" },
          alignTicks: false,
          opposite: true /*the second axis is on the other side*/,
        },
      ],
      yAxis: [
        {
          title: {
            text: "Data",
          },
        },
        {
          title: { text: "Frequency" },
          opposite: true,
        },
      ],
      series: [
        {
          name: "Histogram",
          type: "histogram",
          xAxis: 1,
          yAxis: 1,
          baseSeries: "s1",
          zIndex: -1,
        },
        {
          name: "Data",
          type: "scatter",
          data: tc_data,
          id: "s1",
        },
      ],
    },
    // 15: device usage
    {
      chart: {
        id: "device_mix",
        type: "spline",
        styledMode: false,
      },
      title: {
        text: "Device Usage Mix"
      },
      subtitle: {
        text: "Q1 & Q2 2024"
      },

      xAxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        title: {
          text: "Time",
        },
      },
      yAxis: {
        title: {
          text: "Percentage usage",
        },
      },
      tooltip: {
        valueSuffix: "%",
        stickOnContact: true,
      },

      series: [
        {
          name: "Mobile",
          data: [34.8, 43.0, 51.2, 41.4, 64.9, 72.4],
          dashStyle: "Solid",
        },
        {
          name: "Desktop",
          data: [69.6, 63.7, 63.9, 43.7, 66.0, 61.7],
          dashStyle: "ShortDashDot",
        },
        {
          name: "Tablet",
          data: [20.2, 30.7, 36.8, 30.9, 39.6, 47.1],
          dashStyle: "ShortDot",
        },
      ],
    },
    // 16: health metrics before vs after medicine treatment
    {
      chart: {
        id: "before_after_treatment",
        type: "dumbbell",
        styledMode: false,
        inverted: true,
      },
      title: { 
        text: "Before vs After Medicine Usage"
      },
      subtitle: {
        text: "Initial Assessment & 1st Reassessment"
      },
      xAxis: {
        type: "category",
        opposite: true,
      },
      yAxis: {
        title: null,
      },

      tooltip: {
        pointFormat:
          "Before Usage: has value " +
          "<strong>{point.previous}</strong> <br>" +
          "After Usage: has value <strong>{point.current}</strong> ",
        shared: true,
      },

      series: [
        {
          name: "Increase",
          data: increasingData,
          lowMarker: {
            enabled: false,
          },
          marker: {
            enabled: true,
            symbol: "triangleRight",
          },
        },
        {
          name: "Decrease",
          data: decreasingData,
          marker: {
            enabled: false,
          },
          lowColor: undefined,
          lowMarker: {
            enabled: true,
            symbol: "triangleLeft",
          },
        },
      ],
    },
  ];
};

export { useACNUData };
