const ExternalLink = ({ url, className, children }) => {
  const handleClick = (e) => {
    e.preventDefault();
    window.open(url, "_blank");
  };

  return (
    <a className={className || ''} href={url} target="_blank" rel="noreferrer" onClick={handleClick}>
      {children}
    </a>
  );
};

export default ExternalLink;