import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

import './KeywordField.css';

const KeywordField = ({ list = [], type, label, placeholder, onAdd, disabled = false, keywordLimit = 500, children }) => {
  const [inputValue, setInputValue] = useState('');
  const [focused, setFocused] = useState(false);

  const handleOnAdd = (type, value) => {
    if (value) {
      onAdd(type, value);
      setInputValue('');
    }
  }

  return <div className={`keywords-form-group ${ focused ? 'focused' : ''}`}>
    { label && <label htmlFor={`input-${type}`}>{ label }</label>}

    <div className="keyword-input">
      <input 
        type="text" 
        id={`input-${type}`}
        placeholder={placeholder}
        value={inputValue}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleOnAdd(type, inputValue);
          }
        }}
        disabled={disabled || list.length >= keywordLimit}
      />
      <div className="p-2">
        <button
          className="h-full w-full aspect-square"
          type="button"
          onClick={() => handleOnAdd(type, inputValue)}
          disabled={disabled || list.length >= keywordLimit}
          data-testid={`add-${type}`}
        >
          <FontAwesomeIcon icon={faPlus} />
          <span className="sr-only">add { type } keyword</span>
        </button>
      </div>
    </div>

    <div className={`keywords-group ${disabled ? 'opacity-50' : 'opacity-100'}`}>
      { children }
      <div className={`keywords-count 
        ${keywordLimit && list.length >= keywordLimit - 3 && list.length < keywordLimit ? 'warning' : ''}
        ${keywordLimit && list.length === keywordLimit ? 'limit' : ''}`
      }
      >
        { list.length } / { keywordLimit }
      </div>
    </div>
  </div>
};

KeywordField.KeywordItem = ({ children, onRemove, disabled }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  }

  const handleBlur = () => {
    setIsFocused(false);
  }

  return (
    <div className={`keyword-item ${isHovered ? 'hovered' : ''} ${isFocused ? 'focused' : ''}`}>
      <span>{children}</span>

      { onRemove && (
        <button 
          type="button" 
          className="remove-btn" 
          onClick={() => onRemove()} 
          disabled={disabled}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onFocus={handleFocus}
          onBlur={handleBlur}
          aria-label={`Remove ${children}`}
          aria-disabled={disabled}
          data-testid={`remove-${children}`}
        >
          <FontAwesomeIcon icon={faTimes} size="xs" />
          <span className="sr-only">Remove { children }</span>
        </button>
      )}
    </div>
  );
}

export default KeywordField;