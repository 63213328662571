export const SliderRow = ({ name, description, y, onValueChange }) => {
  return (
    <div className={`flex flex-col mb-4`}>
      <div className={`mb-4`}>
        <strong className="block text-deep-space dark:text-white">{name}</strong>
        { description && <p className="text-sm text-deep-space dark:text-white">{description}</p>}
      </div>
      <div className="flex gap-2">
        <span className="flex-1">
          <input
            type="range"
            min={0}
            max={100}
            value={y}
            onChange={(e) => onValueChange(parseInt(e.target.value, 10))}
            aria-label={name}
            className={`flex-1 accent-blue-500`}
          />
        </span>
        <span className="w-12 text-center">{`${y}%`}</span>
      </div>
    </div>
  );
};

const DistributionSliders = ({ goals, setGoals  }) => {
  const total = goals.reduce((sum, g) => sum + g.y, 0);

  const getMaxRange = (goals, index) => {
    const totalOtherValues = goals.reduce((sum, goal, i) => (i !== index ? sum + goal.y : sum), 0);
    return Math.max(0, 100 - totalOtherValues); // Ensure at least 0
  };

  const handleValueChange = (index, newValue) => {
    setGoals((prevGoals) => {
      const maxRange = getMaxRange(prevGoals, index);

      // Clamp the slider's value to the allowed range
      const clampedValue = Math.min(newValue, maxRange);

      const goalsCopy = [...prevGoals];
      goalsCopy[index] = { ...goalsCopy[index], y: clampedValue };

      return goalsCopy;
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <p className="text-sm">
          Adjust sliders to distribute the total percentage.
        </p>

        <span className={`min-w-[53px] text-center text-white rounded py-1 px-2 ${ total < 100 ? 'bg-watermelon-500' : 'bg-[#2DB300]'}`}>
          { total }%
        </span>
      </div>
      <div className={`h-full grid grid-rows-2 grid-cols-1 md:grid-rows-1 md:grid-cols-2 gap-4`}>
        {goals.map((goal, i) => (
          <SliderRow
            key={i}
            name={goal.name}
            description={goal.description}
            y={goal.y}
            onValueChange={(val) => handleValueChange(i, val)}

          />
        ))}
      </div>
    </div>
  );
};

export default DistributionSliders;
