import { useEffect, useState } from "react";

import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";
import { useTheme } from "../../../../utils/ThemeManager.jsx";

// data: project.condition_analysis.population_chart_input
const PrescriptionTrend = ({ data, project_name }) => {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});


  useEffect(() => {
    if (data == null || Object.keys(data).length === 0) return;

    setOptions(lineChartOptions(data, project_name, theme));
  }, [theme]);

  if (data == null || Object.keys(data).length === 0) {
    return (
      <p className="px-4 py-8 font-bold text-center">
        Prescription trend data is not available.
      </p>
    );
  }

  return (
    <div>
      <ChartDashboard config={options} id="population-container" />
    </div>
  );
};

export default PrescriptionTrend;

// chart options
const lineChartOptions = (data, project_name, theme) => {
  const series = [];
  const colors = {
    dark: ["#FF7347", "#3AE500"],
    light: ['#0561B7', '#FF7347'],
  }

  // Check if the first series exists and is valid
  if (data.labels[0] && data.data1 && data.data1.length > 0) {
    series.push({
      name: data.labels[0],
      data: data.data1,
      marker: {
         fillColor: theme === 'dark' ? '#020021' : '#FFFFFF',  
         lineColor: colors[theme][0]
       },
    });
  }

  // Check if the second series exists and is valid
  if (data.labels[1] && data.data2 && data.data2.length > 0) {
    series.push({
      name: data.labels[1],
      data: data.data2,
      marker: {
        fillColor: theme === 'dark' ? '#020021' : '#FFFFFF',
        lineColor: colors[theme][1]
      },
    });
  }

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: "population-cell-0",
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: "population-cell-0",
        chartOptions: {
          colors: colors[theme] || colors.dark,
          chart: {
            type: "line",
            height: 440,
            styledMode: false,
            spacingLeft: -1,
            spacingRight: -1,
            spacingTop: 1,
            spacingBottom: 1,
          },
          title: {
            text: data.title || null,
          },
          subtitle: {
            // Project: {project_name} | {subtitle}
            text: `${data.subtitle} ${ project_name ? `| Project: ${project_name}` : '' }`,
          },
          xAxis: {
            tickmarkPlacement: 'on',
            categories: data.year || [],
            accessibility: {
              rangeDescription: "Date range by year", // need to provide a valid description from backend
            },
          },
          yAxis: {
            title: {
              text: data.yAxisText || null,
            },
          },
          series: series,
        },
      },
    ],
  };
};
