// react
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// hooks
import useCompanies from "./hooks/useCompanies";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";

// components
import CompanyItem from "./CompanyItem";
import ProjectFilter from "../../components/ProjectFilter/ProjectFilter";
import CompaniesPagination from "./CompaniesPagination";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";

import image from "./ai_image.webp"

// styles
import "./Companies.css";

const Companies = () => {
  
  const {
    companies,
    loading,
    error,
    currentPage,
    setCurrentPage,
    totalPages,
    search,
    setSearch,
  } = useCompanies();

  const [selectedDifficulties, setSelectedDifficulties] = useState({
    lowest: true,
    low: true,
    moderate: true,
    high: true,
    highest: true,
  });

  // to focus on search input
  const searchInputRef = useRef(null);

  // Function to handle toggle changes
  const handleToggle = (difficulty) => {
    setSelectedDifficulties((prev) => ({
      ...prev,
      [difficulty]: !prev[difficulty],
    }));
  };

  const handleSetSearch = (e) => {
    // if leading space, do not set search
    if (e.target.value.startsWith(" ")) return;

    setSearch(e.target.value);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  // if (error) return <p>Error loading companies!</p>;

  return (
    <div className="companies-page page-base">
      <div className="page-header">
        <div className="col-1">
          <h1 className="mb-1">Discover</h1>
          <p className="ml-3 text-[#838190]">Discover Opportunities</p>
        </div>

        <div className="col-2">
          <Link className="btn header-btn" to="/project-status">
            <span className="hidden md:inline-block">Update Queue</span>
            <FontAwesomeIcon icon={faClockRotateLeft} />
          </Link>
        </div>
      </div>

      <div className="flex flex-col my-2 md:my-4 md:mb-12 md:flex-row gap-2">
        <form action="" className="search-bar flex-1" role="search">
          <label className="mb-2 text-xs opacity-0" htmlFor="search">
            Company Search
          </label>
          <input
            className={`w-full p-4 rounded border border-[#0561B7] border-opacity-[0.25] dark:border-[#0561B7] dark:border-opacity-50 focus:ring focus:ring-[#0561b780] focus:dark:ring-[#0561b7] ${ error ? "cursor-not-allowed" : "" }`}
            type="text"
            value={ search }
            onChange={handleSetSearch}
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder="Search for Conditions, Companies, and Brands"
            disabled={ error }
            ref={searchInputRef}
          />
        </form>

        <ProjectFilter
          selectedDifficulties={selectedDifficulties}
          handleToggle={handleToggle}
        />
      </div>


      { (search === '' || search === null || loading) && <div className={``}>
          {/* <img src={image} alt="AI" className=" w-full mx-auto opacity-[0.07]" aria-hidden="true" /> */}
        </div> 
      }

      { error && <ErrorDisplay error={error} /> }

      {search !== '' && !loading && !error && <ul
          className={`company-list ${loading ? "animate-pulse cursor-wait" : ""}`}
          data-testid="company-list"
        >
          { companies?.length > 0 
            ? companies.map((company, index) => (
              <CompanyItem
                key={`${company.uuid}-${index}`}
                company={company}
                selectedDifficulties={selectedDifficulties}
              />
            )) : <div className="text-center py-16 duration-500 ease-in-out" style={{ 
              opacity: 0,
              animation: 'fadeIn',
              animationDelay: '100ms',
              animationDuration: '100ms',
              animationFillMode: 'forwards',
              animationTimingFunction: 'ease-in-out'
            }}>
              <h2>No results found</h2>
              <p>Try searching for a different company or brand</p>
            </div>
          }
      </ul>}

      <CompaniesPagination
        loading={loading}
        error={error}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />

    </div>
  );
};

export default Companies;
